import React, {
    useEffect,
    useState,
    useRef
} from 'react';
// TODO: API calls
import { handleMonthYear } from '../../../helpers/genericFunc';
import {
    getTop5ViewedWebinars,
    getWebinarDateInfoViews,
    getTop5EnterpriseViews,
    getWebinarExcelDataByYear
} from '../../../models/webinars_model';
// TODO: Dependencies
import Chart from 'react-apexcharts';
import { ThreeCircles } from 'react-loader-spinner'
// TODO: Utilities
import { colorsArray } from '../../plans/helpers/helpers';

const GeneralInfo = () => {
    // TODO: State
    const monthInputRef = useRef(null);
    const [top5WebinarValue, setTop5WebinarValue] = useState("");
    const [top5WebinarShow, setTop5WebinarShow] = useState(true);
    const [top5EnterpriseShow, setTop5EnterpriseShow] = useState(true);
    const [isDowloading, setIsDowloading] = useState(false);
    const [barChartDataTop5, setBarChartDataTop5] = useState({});
    const [barChartDataTop5Enterprise, setBarChartDataTop5Enterprise] = useState({});
    const [pieChartDataVideoTop5, setPieChartDataVideoTop5] = useState({
        visible: false, // Estado para controlar visibilidad
        videoId: 0, // Identificador del video actualmente desglosado
        series: [],
        options: {
            chart: {
                type: "pie",
            },
            title: {
                text: "",
                align: "center",
                style: {
                    fontSize: "18px",
                },
            },
            labels: [],
            colors: colorsArray
        },
    });

    // TODO: useEffect
    /**
     * Guarda la información inicial del Top 5 Webinar
     */
    useEffect(() => {
        const today = new Date();
        const month = today.getMonth() + 1;
        const year = today.getFullYear();
        // Guarda la fecha actual en el state para mostrar en el input date
        setTop5WebinarValue(`${year}-${month}`);
        // Obtiene la información del Top 5 Webinars
        getTop5ViewedWebinars(month, year).then(response => {
            if (response.validation) {
                const top5webinarsData = response.data.dataWebinars;
                const top5webinarsSeries = [
                    {
                        name: 'Reproducciones',
                        data: [
                            top5webinarsData[0].totalViews,
                            top5webinarsData[1].totalViews,
                            top5webinarsData[2].totalViews,
                            top5webinarsData[3].totalViews,
                            top5webinarsData[4].totalViews,
                        ],
                    },
                ];
                const viewsArr = top5webinarsSeries[0].data;
                const maxView = Math.max(...viewsArr);
                const objtop5webinars = {
                    series: top5webinarsSeries,
                    options: {
                        chart: {
                            type: "bar",
                            id: 'top5webinars2',
                            events: {
                                click: (event, chartContext, config) => {
                                    if (config.dataPointIndex !== -1) {
                                        const videoName = top5webinarsData[config.dataPointIndex];
                                        handleBarClick(videoName, month, year); // Manejar clic
                                    }
                                },
                            },
                        },
                        xaxis: {
                            categories: [
                                { id: top5webinarsData[0].idWebinar, name: top5webinarsData[0].title },
                                { id: top5webinarsData[1].idWebinar, name: top5webinarsData[1].title },
                                { id: top5webinarsData[2].idWebinar, name: top5webinarsData[2].title },
                                { id: top5webinarsData[3].idWebinar, name: top5webinarsData[3].title },
                                { id: top5webinarsData[4].idWebinar, name: top5webinarsData[4].title },
                            ],
                            labels: {
                                formatter: function (val) {
                                    return val.name; // Mostrar el nombre en el gráfico
                                },
                                style: {
                                    fontSize: '12px', // Reduce el tamaño de las etiquetas
                                },
                                rotate: -70, // Rota las etiquetas para mejorar la legibilidad
                                trim: true, // Ajusta el texto automáticamente
                            },
                        },
                        yaxis: {
                            title: {
                                text: "Número de Reproducciones",
                                offsetX: 10, // Ajuste para evitar que las etiquetas tapen el título
                            },
                            min: 0, // Valor mínimo del eje Y
                            max: maxView, // Valor máximo del eje Y, ajusta según tus datos
                            labels: {
                                formatter: function (value) {
                                  return value.toFixed(0); // Asegura que solo se muestren enteros
                                },
                                offsetX: 0, // Asegura que las etiquetas no desplacen el título
                            },
                        },
                        title: {
                            text: "Top 5 de Webinars más vistos",
                            align: "center",
                            style: {
                                fontSize: "15px",
                            },
                        },
                    },
                }
                setBarChartDataTop5(objtop5webinars)
            } else {
                setTop5WebinarShow(false);
            }
        });
    }, []);
    /**
     * Guarda la información inicial del Top 5 Empresas
     */
    useEffect(() => {
        const today = new Date();
        const month = today.getMonth() + 1;
        const year = today.getFullYear();
        // Obtiene la información del Top 5 Empresas
        getTop5EnterpriseViews(month, year).then(response => {
            if (response.validation) {
                const top5EnterpriseViewData = response.data.topList;
                if (top5EnterpriseViewData.length === 0) {
                    setTop5EnterpriseShow(false);
                } else {
                    let top5EnterpriseSeriesData = [];
                    let objtop5EnterpriseCategories = [];
                    for (let index = 0; index < top5EnterpriseViewData.length; index++) {
                        const element = top5EnterpriseViewData[index];
                        top5EnterpriseSeriesData.push(element.count);
                        objtop5EnterpriseCategories.push({ id: element.idEnterprise, name: element.comercialName });
                    }
                    const top5EnterpriseSeries = [
                        {
                            name: 'Reproducciones',
                            data: top5EnterpriseSeriesData
                        },
                    ];
                    const maxView = Math.max(...top5EnterpriseSeriesData);
                    const objtop5Enterprise = {
                        series: top5EnterpriseSeries,
                        options: {
                            chart: {
                                type: "bar",
                                id: 'top5Enterprise2',
                                events: {
                                    click: (event, chartContext, config) => {
                                        if (config.dataPointIndex !== -1) {
                                            const videoName = top5EnterpriseViewData[config.dataPointIndex];
                                            console.log(videoName)
                                            handleBarClick(videoName, month, year); // Manejar clic
                                        }
                                    },
                                },
                            },
                            xaxis: {
                                categories: objtop5EnterpriseCategories,
                                labels: {
                                    formatter: function (val) {
                                        return val.name; // Mostrar el nombre en el gráfico
                                    },
                                    style: {
                                        fontSize: '12px', // Reduce el tamaño de las etiquetas
                                    },
                                    rotate: -70, // Rota las etiquetas para mejorar la legibilidad
                                    trim: true, // Ajusta el texto automáticamente
                                },
                            },
                            yaxis: {
                                title: {
                                    text: "Número de Reproducciones",
                                    offsetX: -2, // Ajuste para evitar que las etiquetas tapen el título
                                },
                                min: 0, // Valor mínimo del eje Y
                                max: maxView, // Valor máximo del eje Y, ajusta según tus datos
                                labels: {
                                    formatter: function (value) {
                                    return value.toFixed(0); // Asegura que solo se muestren enteros
                                    },
                                    offsetX: 0, // Asegura que las etiquetas no desplacen el título
                                },
                            },
                            title: {
                                text: "Top 5 de Empresas con reproducciones",
                                align: "center",
                                style: {
                                    fontSize: "15px",
                                },
                            },
                        },
                    }
                    setBarChartDataTop5Enterprise(objtop5Enterprise)
                }
            } else {
                setTop5EnterpriseShow(false);
            }
        });
    }, []);
    // Actualizar el gráfico de pastel al seleccionar una barra del top5
    const handleBarClick = (videoId, month, year) => {
        let date = `${month}-${year}`
        let isSameVideo = false;
        setPieChartDataVideoTop5((prevState) => {
            if (prevState.visible && prevState.videoId === videoId.idWebinar) {
                // Si el video seleccionado es el mismo, oculta el gráfico
                isSameVideo = true;
                return {
                    ...prevState,
                    visible: false,
                    videoId: null,
                    series: [],
                    options: {
                        ...prevState.options,
                        labels: [],
                    },

                };
            }
            return prevState; // No actualices hasta obtener nuevos datos
        });
        if (date !== "") {
            if (!isSameVideo) {
                const dateParts = date.split("-");
                // console.log('dateParts: ',dateParts);
                const objEnterpriseTopInfo = {
                    idWebinar: videoId.idWebinar,
                    month: dateParts[0],
                    year: dateParts[1]
                }
                // console.log('objEnterpriseTopInfo: ',objEnterpriseTopInfo);
                getWebinarDateInfoViews(objEnterpriseTopInfo).then(response => {
                    if (response.validation) {
                        if (response.data.enterpriseViews.length > 0) {
                            const arrEnterprises = response.data.enterpriseViews.map(e => e.comercialName);
                            const arrEnterprisesCount = response.data.enterpriseViews.map(e => e.count);
                            setPieChartDataVideoTop5((prevState) => {
                                return {
                                    visible: true,
                                    videoId: videoId.idWebinar,
                                    series: arrEnterprisesCount,
                                    options: {
                                        ...prevState.options,
                                        labels: arrEnterprises,
                                        title: {
                                            text: `${videoId.title} (Reproducciones por empresa)`,
                                            align: "center",
                                            style: {
                                                fontSize: "18px",
                                            },
                                        },
                                    },
                                };
                            });
                        } else {
                            setPieChartDataVideoTop5((prevState) => {
                                return {
                                    ...prevState,
                                    visible: false,
                                    videoId: null,
                                    series: [],
                                    options: {
                                        ...prevState.options,
                                        labels: [],
                                    },
                                };
                            });
                        }
                    }
                });
            }
        }
    };
    /**
     * Maneja la función para abrir el input date al dar clic al botón de referencia
     */
    const handleButtonClick = () => {
        if (monthInputRef.current) {
            monthInputRef.current.showPicker(); // Mostrar el calendario directamente (soporte en navegadores modernos)
        }
    };
    /**
     * Manje el cambio de fehca para el Top 5 de Webinars
     * @param {*} e 
     */
    const handleInputDate = (e) => {
        console.log(e.target.value);
        const getDate = e.target.value;
        const getDateSplit = getDate.split("-");
        const month = getDateSplit[1];
        const year = getDateSplit[0];
        // Reiniciamos el chart que va dentro del top 5
        setPieChartDataVideoTop5((prevState) => {
            return {
                ...prevState,
                visible: false,
                videoId: null,
                series: [],
                options: {
                    ...prevState.options,
                    labels: [],
                },
            };
        });
        // Actualizar datos del Top 5 Webinars
        getTop5ViewedWebinars(month, year).then(response => {
            if (response.validation) {
                //console.log(response.data);
                const top5webinarsData = response.data.dataWebinars;
                if (top5webinarsData[0].totalViews === 0) {
                    setTop5WebinarShow(false);
                } else {
                    const top5webinarsSeries = [
                        {
                            name: 'Reproducciones',
                            data: [
                                top5webinarsData[0].totalViews,
                                top5webinarsData[1].totalViews,
                                top5webinarsData[2].totalViews,
                                top5webinarsData[3].totalViews,
                                top5webinarsData[4].totalViews,
                            ],
                        },
                    ];
                    const objtop5webinars = {
                        series: top5webinarsSeries,
                        options: {
                            chart: {
                                type: "bar",
                                id: 'top5webinars2',
                                events: {
                                    click: (event, chartContext, config) => {
                                        if (config.dataPointIndex !== -1) {
                                            const videoName = top5webinarsData[config.dataPointIndex];
                                            handleBarClick(videoName, month, year); // Manejar clic
                                        }
                                    },
                                },
                            },
                            xaxis: {
                                categories: [
                                    { id: top5webinarsData[0].idWebinar, name: top5webinarsData[0].title },
                                    { id: top5webinarsData[1].idWebinar, name: top5webinarsData[1].title },
                                    { id: top5webinarsData[2].idWebinar, name: top5webinarsData[2].title },
                                    { id: top5webinarsData[3].idWebinar, name: top5webinarsData[3].title },
                                    { id: top5webinarsData[4].idWebinar, name: top5webinarsData[4].title },
                                ],
                                labels: {
                                    formatter: function (val) {
                                        return val.name; // Mostrar el nombre en el gráfico
                                    },
                                    style: {
                                        fontSize: '12px', // Reduce el tamaño de las etiquetas
                                    },
                                    rotate: -70, // Rota las etiquetas para mejorar la legibilidad
                                    trim: true, // Ajusta el texto automáticamente
                                },
                            },
                            yaxis: {
                                title: {
                                    text: "Número de Reproducciones",
                                },
                            },
                            title: {
                                text: "Top 5 de Webinars más vistos",
                                align: "center",
                                style: {
                                    fontSize: "15px",
                                },
                            },
                        },
                    }
                    setBarChartDataTop5(objtop5webinars)
                    setTop5WebinarShow(true);
                }
                setTop5WebinarValue(getDate);
            } else {
                setTop5WebinarValue(getDate);
                setTop5WebinarShow(false);
            }
        });
        // Actualizar información de Top 5 de Empresas
        getTop5EnterpriseViews(month, year).then(response => {
            if (response.validation) {
                //console.log(response.data);
                const top5EnterpriseViewData = response.data.topList;
                if (top5EnterpriseViewData.length === 0) {
                    setTop5EnterpriseShow(false);
                } else {
                    let top5EnterpriseSeriesData = [];
                    let objtop5EnterpriseCategories = [];
                    for (let index = 0; index < top5EnterpriseViewData.length; index++) {
                        const element = top5EnterpriseViewData[index];
                        top5EnterpriseSeriesData.push(element.count);
                        objtop5EnterpriseCategories.push({ id: element.idEnterprise, name: element.comercialName });
                    }
                    const top5EnterpriseSeries = [
                        {
                            name: 'Reproducciones',
                            data: top5EnterpriseSeriesData
                        },
                    ];
                    const objtop5Enterprise = {
                        series: top5EnterpriseSeries,
                        options: {
                            chart: {
                                type: "bar",
                                id: 'top5Enterprise2',
                            },
                            xaxis: {
                                categories: objtop5EnterpriseCategories,
                                labels: {
                                    formatter: function (val) {
                                        return val.name; // Mostrar el nombre en el gráfico
                                    },
                                    style: {
                                        fontSize: '12px', // Reduce el tamaño de las etiquetas
                                    },
                                    rotate: -70, // Rota las etiquetas para mejorar la legibilidad
                                    trim: true, // Ajusta el texto automáticamente
                                },
                            },
                            yaxis: {
                                title: {
                                    text: "Número de Reproducciones",
                                },
                            },
                            title: {
                                text: "Top 5 de Empresas con reproducciones",
                                align: "center",
                                style: {
                                    fontSize: "15px",
                                },
                            },
                        },
                    }
                    //console.log(objtop5Enterprise);
                    setBarChartDataTop5Enterprise(objtop5Enterprise)
                    setTop5EnterpriseShow(true);
                }
            } else {
                setTop5EnterpriseShow(false);
            }
        });
    };

    const downloadReport = () => {
        setIsDowloading(true);
        console.log('top5WebinarValue: ',top5WebinarValue);
        if(top5WebinarValue !== ''){
            const yearSelected = top5WebinarValue.split("-")[0];
            getWebinarExcelDataByYear(yearSelected).then(response => {
                if (response.validation) {
                    
                    
                }
            });
        }
        
        
        setTimeout(() => {
            setIsDowloading(false)

        }, 1000);
    }   

    return (
        <div className="col-11 mx-auto">
            <div className='card border-0 shadow rounded-25-px generic__show-main-container container-1'>
                <div className='card-body'>
                    <div className='col-sm-11 col-lg-12 col-xxl-12 mx-auto generic__show-container top container-1'>
                        <div className='row'>
                            <div className='col-12 p-0'>
                                <div className='row'>
                                    <div className="col-sm-12 col-lg-6 col-xxl-6">
                                        <div className="input-group mb-3">
                                            <span className='border px-3'>{handleMonthYear(top5WebinarValue)}</span>
                                            <div className="input-group-append">
                                                <button
                                                    type="button"
                                                    className="btn btn-primary btn-sm"
                                                    onClick={handleButtonClick}
                                                >Seleccionar mes
                                                </button>
                                                <input
                                                    type="month"
                                                    name="fecha"
                                                    id="fecha"
                                                    className="form-control"
                                                    ref={monthInputRef}
                                                    style={{
                                                        position: "absolute",
                                                        opacity: 0,
                                                        pointerEvents: "none",
                                                        top: "-10px"
                                                    }} // Oculta el input
                                                    onChange={(e) => handleInputDate(e)}
                                                    value={top5WebinarValue}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-lg-6 col-xxl-6 d-flex justify-content-end px-5">
                                        {
                                            isDowloading ? (
                                                <div className='d-flex'>
                                                    <span className='me-2'>Descargando Reporte General de Excel</span>
                                                    <div className='pl-1 '>
                                                        <ThreeCircles
                                                            height="20"
                                                            width="20"
                                                            visible={true}
                                                            ariaLabel="three-circles-rotating"
                                                            outerCircleColor="#2188b4"
                                                            innerCircleColor="#9cb537"
                                                            middleCircleColor="#af2f72"
                                                        />
                                                    </div>
                                                </div>
                                                
                                            ) : (
                                                <span className="text-blue fake-select text-decoration-underline pointer" onClick={() => downloadReport() }>Descarga Reporte General de Excel</span>
                                            )
                                        }
                                    </div>
                                </div>
                                {/* TOP 5 WEBINARS*/}
                                {!top5WebinarShow ? (
                                    <div className='col-11 mx-auto text-center mb-3'>
                                        <div className='card shadow border-1 rounded-25-px generic__show-main-container container-1'>
                                            <div className='card-body py-5'>
                                                <span className='graphicTitle'>Top 5 de Webinars más vistos</span>
                                                <h1>No hay información</h1>
                                            </div>
                                        </div>
                                    </div>
                                ) :
                                    Object.keys(barChartDataTop5).length > 0 && (
                                        <div className='col-11 mx-auto mb-3'>
                                            <div className='card shadow border-1 rounded-25-px generic__show-main-container container-1'>
                                                <div className='card-body'>
                                                    <Chart
                                                        options={barChartDataTop5.options}
                                                        series={barChartDataTop5.series}
                                                        type="bar"
                                                        height={350}
                                                    />
                                                    {
                                                        pieChartDataVideoTop5.series.length > 0 ?

                                                            pieChartDataVideoTop5.visible && (
                                                                <Chart
                                                                    options={pieChartDataVideoTop5.options}
                                                                    series={pieChartDataVideoTop5.series}
                                                                    type="pie"
                                                                    height={350}
                                                                />
                                                            )

                                                            : pieChartDataVideoTop5.visible && (
                                                                <p>No hay registros del video</p>
                                                            )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                {/* TOP 5 EMPRESAS */}
                                <div className='col-11 mx-auto mb-3'>
                                    <div className='card shadow border-1 rounded-25-px generic__show-main-container container-1'>
                                        <div className='card-body'>
                                            {!top5EnterpriseShow ? (
                                                <div className='col-12 text-center py-5'>
                                                    <span className='graphicTitle'>Top 5 de Empresas con reproducciones</span>
                                                    <h1>No hay información</h1>
                                                </div>
                                            ) : (
                                                Object.keys(barChartDataTop5Enterprise).length > 0 && (
                                                    <Chart
                                                        options={barChartDataTop5Enterprise.options}
                                                        series={barChartDataTop5Enterprise.series}
                                                        type="bar"
                                                        height={350}
                                                    />
                                                )
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GeneralInfo;
